import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import shortHash from "short-hash";

import {
  addAudit,
  fetchAudits,
  removeAudit,
  fetchFlows,
} from "../../../actions";

import Layout from "../../../components/layout";
import { AddNewAudit, TableAudit } from "../../../components";
import { Divider } from "semantic-ui-react";
import Container from "../../../common/components/container";
import { Link } from "gatsby";
// AUDITS

const Index = () => {
  const dispatch = useDispatch();

  //State
  const [audit_name, update_audit_name] = useState("");
  const [flow_selected, update_flow_selected] = useState(null);
  const [loading, update_loading] = useState(true);
  //Redux
  const audits = useSelector((state) => state.audits);
  const user = useSelector((state) => state.user);
  const flows = useSelector((state) => state.flows);

  function handleAddAudit() {
    const date = Date.now();
    const hash = shortHash(date + audit_name);
    console.log("date");
    console.log(date);

    console.log("hash");
    console.log(hash);
    dispatch(
      addAudit({
        name: audit_name,
        practice_id: user.practice_id,
        flow_id: flow_selected,
        hash: hash,
        // hash: "SLP2",
      })
    );
    update_audit_name("");
  }

  function handleRemoveEntry(audit) {
    dispatch(removeAudit(audit));
  }

  function dropdownSelect(value) {
    update_flow_selected(value);
  }

  useEffect(() => {
    dispatch(fetchAudits(user.practice_id));
    dispatch(fetchFlows());
    update_loading(false);
  }, []);

  return (
    <Layout>
      <Container>
        {loading ? (
          <div>Loading...</div>
        ) : (
          <div>
            <div className="pb-5 border-b border-gray-200 sm:flex sm:items-center sm:justify-between">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Audits
              </h3>
              <div className="mt-3 flex sm:mt-0 sm:ml-4">
                {/* <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Share
            </button> */}
                <Link
                  to="/guides/new/"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Create
                </Link>
              </div>
            </div>
            <div>
              <h2>Add Audit</h2>
              <AddNewAudit
                add={{
                  placeholder: "Audit Name",
                  value: audit_name,
                  onChange: update_audit_name,
                  add: handleAddAudit,
                  button_text: "Add Practice",
                }}
                dropdown={{
                  placeholder: "Select Flow",
                  data: flows,
                  select_function: dropdownSelect,
                  dropdown_selected: flow_selected,
                }}
                submit_function={handleAddAudit}
              />
              <Divider />
              <TableAudit
                flows={flows}
                header_array={["Audit Name", "Flow", "Hash", "Created", "asd"]}
                content={audits}
                handleRemoveEntry={handleRemoveEntry}
                // add={handleAddPractice}
              />
            </div>
          </div>
        )}
      </Container>
    </Layout>

    // <Layout>
    //   <div>
    //     <h1>Audits</h1>
    //     <Divider />
    //     <h2>Add Audit</h2>
    //     <AddNewAudit
    //       add={{
    //         placeholder: "Audit Name",
    //         value: audit_name,
    //         onChange: update_audit_name,
    //         add: handleAddAudit,
    //         button_text: "Add Practice",
    //       }}
    //       dropdown={{
    //         placeholder: "Select Flow",
    //         data: flows,
    //         select_function: dropdownSelect,
    //         dropdown_selected: flow_selected,
    //       }}
    //       submit_function={handleAddAudit}
    //     />
    //     <Divider />
    // <TableAudit
    //   flows={flows}
    //   header_array={["Audit Name", "Flow", "Hash", "Created", "asd"]}
    //   content={audits}
    //   handleRemoveEntry={handleRemoveEntry}
    //   // add={handleAddPractice}
    // />
    //   </div>
    // </Layout>
  );
};

export default Index;
